import React, { useEffect, useState } from 'react'
import * as myStyles from './styles.module.scss'
import {
    formErrorTitle,
    formErrorDescription,
    formErrorButtonLabel,
    formErrorButtonLink,
    requestFormTextBlock,
    requestFormFields,
    requestFormPardotHandler,
    requestFormSubmitButtonLabel,
    requestFormSuccessTitle,
    requestFormSuccessDescription,
    requestFormSuccessButtonLabel,
    requestFormSuccessButtonLink,
} from './constants'
import FlexibleModule from '../../lib/components/modules/flexible'
import DynamicForm from '../../lib/components/components/dynamic-form'
import { useForm } from 'react-hook-form'
import { FormState } from '../../lib/components/components/dynamic-form/types'

const RequestQuotePage = () => {
    const styles: any = myStyles

    const [requestFormStatus, setRequestFormStatus] = useState<FormState>('empty')

    const requestForm = useForm<any>({
        mode: 'onBlur',
    })

    useEffect(() => {
        const url = new URL(window.location.href)
        const requestStatus = url.searchParams.get('status')
        if (requestStatus === 'success') setRequestFormStatus('success')
        if (requestStatus === 'error') setRequestFormStatus('error')
    }, [])

    return (
        <div className={styles.request_quote_page}>
            <div className={styles.content}>
                <FlexibleModule textBlocks={requestFormTextBlock} hasBorder={false}>
                    <DynamicForm
                        status={requestFormStatus}
                        fields={requestFormFields}
                        form={requestForm}
                        action={requestFormPardotHandler}
                        submitButtonLabel={requestFormSubmitButtonLabel}
                        responseMessage={{
                            title:
                                requestFormStatus === 'success'
                                    ? requestFormSuccessTitle
                                    : formErrorTitle,
                            description:
                                requestFormStatus === 'success'
                                    ? requestFormSuccessDescription
                                    : formErrorDescription,
                            buttonLabel:
                                requestFormStatus === 'success'
                                    ? requestFormSuccessButtonLabel
                                    : formErrorButtonLabel,
                            buttonLink:
                                requestFormStatus === 'success'
                                    ? requestFormSuccessButtonLink
                                    : formErrorButtonLink,
                        }}
                    />
                </FlexibleModule>
            </div>
        </div>
    )
}

export default RequestQuotePage
