import React, { Fragment } from 'react'
import Layout from '../components/Layout'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'
import RequestQuotePage from '../hanzo/pages/request-quote'

const RootIndex: React.FC = () => {
    const data = useStaticQuery(graphql`
        query RequestQuote {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "QuoteRequest" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Fragment>
            <Layout newsletter={true} linkColor={'dark'}>
                <HeaderSEO pageSEO={pageSEO} />
                <Helmet>
                    <link rel='canonical' href='https://lenses.io/request-quote/' />
                </Helmet>
                <section>
                    <RequestQuotePage />
                </section>
            </Layout>
        </Fragment>
    )
}

export default RootIndex
