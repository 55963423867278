import { generateOptions } from '../../lib/utils/forms'
import {
    PATTERN_EMAIL,
    PATTERN_NO_NUMERIC_STRING,
    PATTERN_STRING,
} from '../../lib/utils/forms/patterns'

// REQUEST QUOTE FORM
export const requestFormFields = [
    {
        name: 'First Name',
        label: 'First Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Last Name',
        label: 'Last Name',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_NO_NUMERIC_STRING, 30),
    },
    {
        name: 'Email',
        label: 'Email',
        type: 'input' as const,
        validation: generateOptions(false, PATTERN_EMAIL, 30),
    },
    {
        name: 'Company',
        label: 'Company',
        type: 'input' as const,
        validation: generateOptions(true, PATTERN_STRING, 30),
    },
    {
        name: 'Contact Us Message',
        label: 'Describe your data streaming infrastructure and use case',
        type: 'textarea' as const,
    },
]

export const requestFormPardotHandler = 'https://go.pardot.com/l/877242/2020-08-04/4fkwlc'

export const requestFormTextBlock = [
    {
        title: 'Request a quote',
        description: `
            <div>
                <p>Please share some basic information about your data streaming project:</p>
                <p>• How many Apache Kafka clusters are you running?</p>
                <p>• How many developers are working with streaming data?</p>
                <p>• What kind of projects do your streaming initiatives support?</p>
                <p>• What are your main challenges with data streaming?</p>
                <p>Book a demo</p>
                <p>Contact Sales at <a href='mailto:sales@lenses.io'>sales@lenses.io</a></p>
                <p>Contact Support at <a href='mailto:support@lenses.io'>support@lenses.io</a></p>
            </div>`,
    },
]

export const requestFormSubmitButtonLabel = 'Send'
export const requestFormSuccessTitle = 'Request received'
export const requestFormSuccessDescription =
    'Thanks for sending a quote request. We’ll review your requirements and get back to you with a proposal.'
export const requestFormSuccessButtonLabel = 'Try now'
export const requestFormSuccessButtonLink = '/apache-kafka-docker'
export const formErrorTitle = 'There has been an error'
export const formErrorDescription =
    'There has been an error when sending your request. Please try again.'
export const formErrorButtonLabel = 'Try again'
export const formErrorButtonLink = '/request-quote'
